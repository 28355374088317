import $ from "jquery";

(function (window, document, $) {

    $(document).ready(function () {

        const formatValue = function (value) {
            return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €' : '- €';
        }

        const $simulaPrestito = $('.tim-simula-prestito');
        if ($simulaPrestito.length > 0) {

            const $amountPicker = $simulaPrestito.find('#amount');
            $amountPicker.trigger('init', {
                'custom_display_fn': function (num) {
                    return formatValue(num);
                }
            });

            const $installmentsSlider = $simulaPrestito.find('#installments_slider');

            const $channelSelector = $simulaPrestito.find('#channel');

            const $promoCodeInput = $simulaPrestito.find('#promo-code');


            const preventivatore = new P({
                promo_controllers: $promoCodeInput,
                channel_controllers: $channelSelector,
                amount_controllers: $amountPicker,
                installment_controllers: $installmentsSlider
            }, {
                forced_channel: window.forza_canale,
                hidden_initial_channel: 'AGENTI'
            });
            preventivatore.register('loading', (value) => {
                if (value === true) {
                    $simulaPrestito.addClass("loading");
                } else {
                    $simulaPrestito.removeClass("loading");
                }
            });
            preventivatore.register('selected_offer_changed', function (offer) {
                $simulaPrestito.next().html(offer ? offer.disclaimer : '');
            });
            preventivatore.register('invalid_promo_code', (value) => {
                const $invalidCodeMessage = $simulaPrestito.find('.tim-invalid-promo-code');
                $invalidCodeMessage.show();
            });
            preventivatore.register('reset_invalid_promo_code', (value) => {
                const $invalidCodeMessage = $simulaPrestito.find('.tim-invalid-promo-code');
                $invalidCodeMessage.hide();
            });

            preventivatore.init();

            $channelSelector.on('cta_clicked', function (evt, location_href) {
                evt.preventDefault();
                var stopRedirect = false;
                const selectedParams = preventivatore.getSelectedParameters();
                const queryString = new URLSearchParams(selectedParams).toString();

                if (!stopRedirect) {
                    location.href = location_href + '?' + queryString;
                }
            });
        }
    })
})(window, document, jQuery);
