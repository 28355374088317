(function (window, document, $) {

  $(document).ready(function () {
    $toggleSliderComponent = $('.tim-toggle-slider');
    if ($toggleSliderComponent.length > 0) {

      $toggleSliderComponent.on('set_value', function (e, newValue) {
        const $slider = $(this);
        const $valueList = $slider.data('values');
        const $inputRange = $slider.find('input[type="range"]');
        const $inputHidden = $slider.find('input[type="hidden"]');
        $inputRange.val($valueList.indexOf(newValue)).change();
        $inputHidden.val(newValue).change();
        $slider.data('value', newValue);
        $inputRange.rangeslider('update', true);
      });

      $toggleSliderComponent.on('set_list', function (e, newList) {
        const $slider = $(this);
        const $firstLabel = $slider.find('.tim-first');
        const $lastLabel = $slider.find('.tim-last');
        const $inputRange = $slider.find('input[type="range"]');
        const $inputHidden = $slider.find('input[type="hidden"]');
        $slider.data('values', newList);
        $firstLabel.html(newList.length ? newList[0] : '-');
        $lastLabel.html(newList.length ? newList[newList.length - 1] : '-');

        if (!newList.includes(parseInt($inputHidden.val()))) {
          $slider.trigger('set_value', newList.length ? newList[0] : '-');
        } else {
          $slider.trigger('set_value', parseInt($inputHidden.val()));
        }

        $inputRange.attr('max', newList.length ? (newList.length - 1) : 0).change();
        $inputRange.rangeslider('update', true);
        //$input.val(0).change();

        // $input.val(newValue).change();
      });

      $toggleSliderComponent.find('input[type="range"]').rangeslider({

        // Feature detection the default is `true`.
        // Set this to `false` if you want to use
        // the polyfill also in Browsers which support
        // the native <input type="range"> element.
        polyfill: false,

        rangeClass: 'rangeslider',
        disabledClass: 'rangeslider--disabled',
        horizontalClass: 'rangeslider--horizontal',
        fillClass: 'rangeslider__fill',
        handleClass: 'rangeslider__handle',

        // Callback function
        onInit: function () {
          const valuesList = $toggleSliderComponent.data('values') || [];
          $rangeEl = this.$range;
          // add value label to handle
          var $handle = $rangeEl.find('.rangeslider__handle');
          var handleValue = '<div class="rangeslider__handle__value">' + valuesList[this.value] + '</div>';
          $handle.append(handleValue);
          $toggleSliderComponent.find('input[type="hidden"]').val(valuesList[this.value]);
          $toggleSliderComponent.data('value', valuesList[this.value]);

        },

        // Callback function
        onSlide: function (position, value) {
          const valuesList = $toggleSliderComponent.data('values') || "[]";
          var $handle = this.$range.find('.rangeslider__handle__value');
          $handle.text(valuesList[this.value]);
          $toggleSliderComponent.find('input[type="hidden"]').val(valuesList[this.value]);
          $toggleSliderComponent.data('value', valuesList[this.value]);
        },

        // Callback function
        onSlideEnd: function (position, value) {
          $toggleSliderComponent.trigger('value_changed', $toggleSliderComponent.find('input[type="hidden"]').val());
        }
      });
    }
  });

})(window, document, jQuery);
