import $ from "jquery";

class Preventivatore {
  constructor({
    promo_controllers = null,
    channel_controllers = null,
    amount_controllers = null,
    installment_controllers = null,
    insurance_controllers = null
  } = {}, {
    forced_promo = null,
    forced_channel = null,
    hidden_initial_channel = null
  } = {}) {
    this.promo = '';
    this.channel = window.preventivatore_defaults.channel ?? 'AGENTI';
    this.amount = window.preventivatore_defaults.amount ?? 10000;
    this.installments = window.preventivatore_defaults.installments ?? 72;
    this.insurance = window.preventivatore_defaults.insurance ?? false;

    this.promo_controllers = promo_controllers || null;
    this.channel_controllers = channel_controllers || null;
    this.amount_controllers = amount_controllers || null;
    this.installment_controllers = installment_controllers || null;
    this.insurance_controllers = insurance_controllers || null;

    this.forced_promo = forced_promo;
    this.forced_channel = forced_channel;
    this.hidden_initial_channel = hidden_initial_channel;

    this._all_data = [];
    this._current_data_table = [];
    this._current_active_offer = [];
    this._unique_channels_available = [];
    this._unique_amounts_available = [];
    this._unique_installments_available = [];

    this.event_subscribers = {
      'loading': [],
      'selected_offer_changed': [],
      'invalid_promo_code': [],
      'reset_invalid_promo_code': []
    };
  }

  register(event, fn) {
    if (Object.keys(this.event_subscribers).includes(event)) {
      this.event_subscribers[event].push(fn);
    } else {
      console.error("Preventivatore(): Invalid event subscription (event: " + event + ")");
    }
  }

  triggerEvent(event, data) {
    this.event_subscribers[event].forEach((fn) => {
      fn(data);
    });
  }

  init() {
    this.initPromoController();
    this.initChannelController();
    this.initAmountController();
    this.initInstallmentsController();
    this.initInsuranceController();

    this.loadOffers();
  }

  initPromoController() {
    // this.promo_controllers.forEach((controller) => {
    if (this.promo_controllers) {
      this.promo_controllers.on('value_changed', (e, value) => {
        const self = this;
        self.triggerEvent('reset_invalid_promo_code');
        self.loadOffers(null, (result) => {
          if (!result.length) {
            self.triggerEvent('invalid_promo_code', self.getPromoValue());
            self.promo_controllers.trigger('set_value', '');
            return false;
          }
          return true;
        });
        // if (window.promo_code_debounce_timout) {
        //   clearTimeout(window.promo_code_debounce_timout);
        // }
        // window.promo_code_debounce_timout = setTimeout(function () {
        //
        // }, 300);
      });
    }
    // });
  }
  initChannelController() {
    // this.channel_controllers.forEach((controller) => {
    if (this.channel_controllers) {
      this.channel_controllers.on('value_changed', (e, value) => {
        this.hidden_initial_channel = null;
        this._update();
      });
    }
    // });
  }
  initAmountController() {
    // this.amount_controllers.forEach((controller) => {
    if (this.amount_controllers) {
      this.amount_controllers.on('value_changed', (e, value) => {
        this._update();
      });
    }
    // });
  }
  initInstallmentsController() {
    // this.installment_controllers.forEach((controller) => {
    if (this.installment_controllers) {
      this.installment_controllers.on('value_changed', (e, value) => {
        this._update();
      });
    }
    // });
  }
  initInsuranceController() {
    // this.insurance_controllers.forEach((controller) => {
    if (this.insurance_controllers) {
      this.insurance_controllers.on('value_changed', (e, value) => {
        this._update();
      });
    }
    // });
  }

  setInstallmentsController($node) {
    const $currentValues = this.installment_controllers.data('values');
    const $currentValue = this.getInstallmentsValue();
    this.installment_controllers = $node;
    this.initInstallmentsController();
    this.installment_controllers.trigger('set_value', $currentValue);
    this.installment_controllers.trigger('set_list', [$currentValues]);
  }

  getPromoValue() {
    // return this.promo_controllers.length ? this.promo_controllers[0].data('value') : this.promo;
    return this.forced_promo ? this.forced_promo : (this.promo_controllers ? this.promo_controllers.data('value') : this.promo);
  }

  getChannelValue() {
    // return this.channel_controllers.length ? this.channel_controllers[0].data('value') : this.channel;
    const value = this.channel_controllers && this.channel_controllers.data('value').length ? this.channel_controllers.data('value') : null;
    const hidden_value = this.channel_controllers && this.channel_controllers.data('hidden-value').length ? this.channel_controllers.data('hidden-value') : null;
    return this.forced_channel ? this.forced_channel : (this.channel_controllers ? (value ?? hidden_value) : this.channel);
  }

  getAmountValue() {
    // return parseInt(this.amount_controllers.length ? this.amount_controllers[0].data('value') : this.amount);
    return parseInt(this.amount_controllers ? this.amount_controllers.data('value') : this.amount);
  }

  getInstallmentsValue() {
    // return parseInt(this.installment_controllers.length ? this.installment_controllers[0].data('value') : this.installments);
    return parseInt(this.installment_controllers ? this.installment_controllers.data('value') : this.installments);
  }

  getInsuranceValue() {
    // return parseInt(this.this.insurance_controllers.length ? this.this.insurance_controllers[0].data('value') : this.insurance);
    return this.insurance_controllers ? this.insurance_controllers.data('value') : this.insurance;
  }

  _update() {

    this.promo = this.getPromoValue();

    this._unique_channels_available = [...new Set(this._all_data.flat().map(item => item.canale))];
    // this.channel_controllers.forEach((controller) => {
    if (this.channel_controllers) {
      this.channel_controllers.trigger('set_list', [this._unique_channels_available]);
    }
    // });
    this.channel = this.getChannelValue();

    this._unique_amounts_available = [...new Set(this.getFilteredData({channel: this.channel}).flat().map(item => item.importo))];
    // this.amount_controllers.forEach((controller) => {
    if (this.amount_controllers) {
      this.amount_controllers.trigger('set_list', [this._unique_amounts_available]);
    }
    // });
    this.amount = this.getAmountValue();

    this._unique_installments_available = [...new Set(this.getFilteredData({channel: this.channel, amount: parseInt(this.amount)}).flat().map(item => item.num_rate))];
    // this.installment_controllers.forEach((controller) => {
    if  (this.installment_controllers) {
      this.installment_controllers.trigger('set_list', [this._unique_installments_available]);
    }
    // });
    this.installments = this.getInstallmentsValue();

    const _current_active_installment_data = this.getFilteredData({channel: this.channel, amount: parseInt(this.amount), installments: this.getInstallmentsValue()});
    this._insurance_available = _current_active_installment_data.filter((data_row) => { return data_row.has_ass; });
    let _insurance_monthly_cost = 0;
    if (this._insurance_available.length) {
      const _insurance_quote = this._insurance_available[0].rata;
      const _no_insurance_quote = _current_active_installment_data.filter((data_row) => { return !data_row.has_ass; })[0].rata;
      _insurance_monthly_cost = (Math.round(((parseFloat(_insurance_quote.replace(',', '.')) - parseFloat(_no_insurance_quote.replace(',', '.'))) + Number.EPSILON) * 100) / 100).toString().replace('.', ',');
    }
    // this.insurance_controllers.forEach((controller) => {
    if  (this.insurance_controllers) {
      this.insurance_controllers.trigger('set_available', this._insurance_available.length ? _insurance_monthly_cost : false);
    }
    // });
    this.insurance = this.getInsuranceValue();

    this._current_data_table = this.getFilteredData({channel: this.channel, amount: parseInt(this.amount), insurance: this.getInsuranceValue()});
    this._current_active_offer = this.getActiveOffer();
    this.triggerEvent('selected_offer_changed', this._current_active_offer);
  }

  getFilteredData(filters) {
    return this._all_data.filter((data_row) => {
      let _ret = true;
      for (let [filter, value] of Object.entries(filters)) {
        if (filter === 'channel' && data_row.canale !== value) {
          _ret = false;
        }
        if (filter === 'amount' && data_row.importo !== value) {
          _ret = false;
        }
        if (filter === 'installments' && data_row.num_rate !== value) {
          _ret = false;
        }
        if (filter === 'insurance' && data_row.has_ass !== value) {
          _ret = false;
        }
      }
      return _ret;
    });
  }

  getActiveOffer() {
    const _current_active_offer = this.getFilteredData({
      channel: this.getChannelValue(),
      amount: this.getAmountValue(),
      installments: this.getInstallmentsValue(),
      insurance: this.getInsuranceValue()
    });
    return _current_active_offer.length ? _current_active_offer[0] : null;
  }

  loadOffers(cb, validationCb) {
    const self = this;

    const data = new FormData();
    data.append("action", 'update_preventivatore');
    if (this.promo_controllers || this.forced_promo) {
      data.append("promo", this.getPromoValue());
    }
    if (this.channel_controllers || this.forced_channel) {
      data.append("channel", this.getChannelValue());
    }
    if (this.amount_controllers) {
      data.append("importo", this.getAmountValue());
    }
    if (this.installment_controllers) {
      data.append("installments", this.getInstallmentsValue());
    }

    this.triggerEvent('loading', true);
    $.ajax({
      type: "POST",
      enctype: 'multipart/form-data',
      url: ajax_url,
      data: data,
      processData: false,
      contentType: false,
      success: function (result) {
        let $res = JSON.parse(result);
        let valid = true;
        if (validationCb) {
          valid = validationCb($res);
        }
        if (valid) {
          self._all_data = $res;
          self._update();
          if (cb) {
            cb($res);
          }
        }
        self.triggerEvent('loading', false);
      }
    });
  }

  getSelectedParameters() {
    return {
      promo_code: this.getPromoValue(),
      canale: this.getChannelValue(),
      importo: this.getAmountValue(),
      durata: this.getInstallmentsValue(),
      assicurazione: this.getInsuranceValue()

    }
  }
}

window.P = Preventivatore;
