(function (window, document, $) {

  const refreshView = function($buttonGroup) {
    let $currValue = $buttonGroup.data('value');
    if ($currValue) {
      $buttonGroup.find('.tim-button-group-button[data-value="' + $currValue + '"]').addClass('selected');
      $buttonGroup.find('.tim-button-group-button:not([data-value="' + $currValue + '"])').removeClass('selected');

      $buttonGroup.find('.tim-button-group-descriptions-card .tim-button-group-description[data-value="' + $currValue + '"]').css('display', 'block');
      $buttonGroup.find('.tim-button-group-descriptions-card .tim-button-group-description:not([data-value="' + $currValue + '"])').css('display', 'none');

      $buttonGroup.find('.tim-button-group-descriptions-accordion .tim-button-group-description[data-value="' + $currValue + '"]').slideDown(500);
      $buttonGroup.find('.tim-button-group-descriptions-accordion .tim-button-group-description:not([data-value="' + $currValue + '"])').slideUp(500);
    }
    const $dataValues = $buttonGroup.data('values');
    $buttonGroup.find('.tim-button-group-button').each((idx, elem) => {
      const $button = $(elem);
      if (!$dataValues.includes($button.data('value'))) {
        $button.addClass('disabled');
      } else {
        $button.removeClass('disabled');
      }
    });


    return false;
  }

  $(document).ready(function () {
    const $buttonGroupComponent = $('.tim-button-group');

    if ($buttonGroupComponent.length > 0) {

      $buttonGroupComponent.on('set_value', function (e, newValue) {
        const $buttonGroup = $(this);
        const $dataValues = $buttonGroup.data('values');
        if ($dataValues.includes(newValue)) {
          if (!$buttonGroup.data('hidden-value')) {
            $buttonGroup.data('value', newValue);
          }
          refreshView($buttonGroup);
        }
      });

      $buttonGroupComponent.on('set_list', function (e, newList) {
        const $buttonGroup = $(this);
        $buttonGroup.data('values', newList);
        const $dataValues = $buttonGroup.data('values');
        if (!$dataValues.includes($buttonGroup.data('value'))) {
          $buttonGroup.trigger('set_value', $dataValues.length ? $dataValues[0] : null);
        } else {
          refreshView($buttonGroup);
        }
      });

      $buttonGroupComponent.on('init', function (e, config) {
        const $buttonGroup = $(this);
        if (config.custom_display_fn) {
          $buttonGroup.data('modFn', config.custom_display_fn);
        }
        refreshView($buttonGroup, false);
      });


      $buttonGroupComponent.find('.tim-button-group-button').on('click touch', function (e) {
        e.preventDefault();
        const $buttonGroup = $(this).closest('.tim-button-group');
        if ($buttonGroup.hasClass('selected')) {
          return;
        }
        const $selectedValue = $(this).data('value');
        $buttonGroup.data('value', $selectedValue);
        if ($buttonGroup.data('hidden-value')) {
          $buttonGroup.data('hidden-value', '');
        }
        $buttonGroup.trigger('value_changed', $selectedValue);
        refreshView($buttonGroup);
      });

      $buttonGroupComponent.find('.tim-button-group-description-cta .tim-button').on('click touch', function (e) {
        e.preventDefault();
        const $buttonGroup = $(this).closest('.tim-button-group');
        const $buttonDescription = $(this).closest('.tim-button-group-description');
        $buttonGroup.trigger('cta_clicked', $(this).attr('href'));
      });

    }
  });

})(window, document, jQuery);
