(function (window, document, $) {

  const refreshView = function($picker) {
    const $input = $picker.find('input');
    const $minusBtn = $picker.find('#btnMinus');
    const $plusBtn = $picker.find('#btnPlus');
    const $reverse = $picker.attr('data-reverse') === "1";
    const modifier = $picker.data('modFn');
    const $dataValues = $picker.data('values');
    const $currValue = $picker.attr('data-real-value');
    const $currIndex = $dataValues.indexOf(parseInt($currValue));

    $picker.data('value', $currValue);

    if (($reverse ? $currIndex >= $dataValues.length - 1 : $currIndex === 0) || $currIndex === -1) {
      $minusBtn.addClass('disabled');
      $minusBtn.prop("disabled", true);
      $minusBtn.trigger('button_disabled');
      if ($dataValues.length > 1) {
        $plusBtn.removeClass('disabled');
        $plusBtn.prop("disabled", false);
      }
    } else {
      $minusBtn.removeClass('disabled');
      $minusBtn.prop("disabled", false);
    }
    if (($reverse ? $currIndex === 0 : $currIndex >= $dataValues.length - 1) || $currIndex === -1) {
      $plusBtn.addClass('disabled');
      $plusBtn.prop("disabled", true);
      $plusBtn.trigger('button_disabled');
      if ($dataValues.length > 1) {
        $minusBtn.removeClass('disabled');
        $minusBtn.prop("disabled", false);
      }
    } else {
      $plusBtn.removeClass('disabled');
      $plusBtn.prop("disabled", false);
    }
    $input.val(modifier ? modifier($currIndex !== -1 ? $currValue : null) : $currValue);
    return false;
  }

  $(document).ready(function () {
    const $minusPlusComponent = $('.tim-select-minus-plus');

    if ($minusPlusComponent.length > 0) {

      $minusPlusComponent.on('set_value', function (e, newValue) {
        const $picker = $(this);
        const $input = $picker.find('input');
        if (newValue) {
          newValue = newValue.toString();
          $picker.attr('data-real-value', newValue);
          $picker.data('value', newValue);
          refreshView($picker);
        } else {
          newValue = 'Nessuna offerta trovata';
          $picker.attr('data-real-value', newValue);
          $picker.data('value', newValue);
          refreshView($picker);
        }
      });

      $minusPlusComponent.on('set_list', function (e, newList) {
        const $picker = $(this);
        $picker.data('values', newList);
        const $dataValues = $picker.data('values');
        if (!$dataValues.includes(parseInt($picker.attr('data-real-value')))) {
          $picker.trigger('set_value', $dataValues.length ? $dataValues[0] : null);
        } else {
          refreshView($picker);
        }
      });

      $minusPlusComponent.on('refresh_view', function (e) {
        const $picker = $(this);
        refreshView($picker);
      });

      $minusPlusComponent.on('init', function (e, config) {
        const $picker = $(this);
        if (config.custom_display_fn) {
          $picker.data('modFn', config.custom_display_fn);
        }
        refreshView($picker);
      });

//      $minusPlusComponent.find('.tim-minus').on('touchstart mousedown', function(evt) {
//        if( evt.which == 3 ) {
//          return;
//        }
//
//        const $minusBtn = $(this);
//        const $componentId = $minusBtn.closest('.tim-select-minus-plus').attr('id');
//        window['picker_touch_minus_timeout_' + $componentId] = setTimeout(function() {
//          window['picker_touch_minus_interval_' + $componentId] = setInterval(() => {
//            $minusBtn.trigger('click');
//          }, 150);
//        }, 400);
//      });
//      $minusPlusComponent.find('.tim-minus').on('touchend mouseup button_disabled', function(evt) {
//        const $minusBtn = $(this);
//        const $componentId = $minusBtn.closest('.tim-select-minus-plus').attr('id');
//        if (window['picker_touch_minus_timeout_' + $componentId]) {
//          clearTimeout(window['picker_touch_minus_timeout_' + $componentId]);
//        }
//        if (window['picker_touch_minus_interval_' + $componentId]) {
//          clearInterval(window['picker_touch_minus_interval_' + $componentId]);
//        }
//      });

      $minusPlusComponent.find('.tim-minus').click(function () {
        //debugger;
        const $picker = $(this).closest('.tim-select-minus-plus');
        const $input = $picker.find('input');
        const $reverse = $picker.attr('data-reverse') === "1";
        const $dataValues = $picker.data('values');
        const $realValue = parseInt($picker.attr('data-real-value'));
        const $currIndex = $dataValues.indexOf($realValue);
        const $newIndex = $reverse ? ($currIndex + 1) : ($currIndex - 1);
        if ($newIndex < 0 || $newIndex > $dataValues.length - 1) {
          return;
        }
        const $newValue = parseInt($dataValues.at($newIndex));
        $picker.attr('data-real-value', $newValue);
        $picker.data('value', $newValue);

        $picker.trigger('value_changed', $newValue);

        refreshView($picker);

      });

//      $minusPlusComponent.find('.tim-plus').on('touchstart mousedown', function(evt) {
//        if( evt.which == 3 ) {
//          return;
//        }
//
//        const $plusBtn = $(this);
//        const $componentId = $plusBtn.closest('.tim-select-minus-plus').attr('id');
//        window['picker_touch_plus_timeout_' + $componentId] = setTimeout(function() {
//          window['picker_touch_plus_interval_' + $componentId] = setInterval(() => {
//            $plusBtn.trigger('click');
//          }, 150);
//        }, 400);
//      });
//      $minusPlusComponent.find('.tim-plus').on('touchend mouseup button_disabled', function(evt) {
//        const $plusBtn = $(this);
//        const $componentId = $plusBtn.closest('.tim-select-minus-plus').attr('id');
//        if (window['picker_touch_plus_timeout_' + $componentId]) {
//          clearTimeout(window['picker_touch_plus_timeout_' + $componentId]);
//        }
//        if (window['picker_touch_plus_interval_' + $componentId]) {
//          clearInterval(window['picker_touch_plus_interval_' + $componentId]);
//        }
//      });

      $minusPlusComponent.find('.tim-plus').click(function () {
        const $picker = $(this).closest('.tim-select-minus-plus');
        const $input = $picker.find('input');
        const $reverse = $picker.attr('data-reverse') === "1";
        const $dataValues = $picker.data('values');
        const $realValue = parseInt($picker.attr('data-real-value'));
        const $currIndex = $dataValues.indexOf($realValue);
        const $newIndex = $reverse ? ($currIndex - 1) : ($currIndex + 1);
        if ($newIndex < 0 || $newIndex > $dataValues.length - 1) {
          return;
        }
        const $newValue = parseInt($dataValues.at($newIndex));
        $picker.attr('data-real-value', $newValue);
        $picker.data('value', $newValue);

        $picker.trigger('value_changed', $newValue);

        refreshView($picker);

      });

    }
  });

})(window, document, jQuery);
