(function (window, document, $) {

  // const refreshView = function($inputApplier) {
  //   const $button = $inputApplier.find('.tim-button');
  //   const $input = $inputApplier.find('input');
  //   $input.val('').change();
  //   $button.removeClass('selected');
  //   let $currValue = $inputApplier.data('value');
  //   if ($currValue) {
  //     $inputApplier.find('.tim-button[data-value="' + $currValue + '"]').addClass('selected');
  //     $inputApplier.find('.tim-button:not([data-value="' + $currValue + '"])').removeClass('selected');
  //   }
  //   const $dataValues = $inputApplier.data('values');
  //   $inputApplier.find('.tim-button').each((idx, elem) => {
  //     const $button = $(elem);
  //     if (!$dataValues.includes($button.data('value'))) {
  //       $button.addClass('disabled');
  //     } else {
  //       $button.removeClass('disabled');
  //     }
  //   });
  //   return false;
  // }

  $(document).ready(function () {
    const $inputApplierComponent = $('.tim-input-applier');

    if ($inputApplierComponent.length > 0) {

      $inputApplierComponent.on('set_value', function (e, newValue) {
        const $inputApplier = $(this);
        const $button = $inputApplier.find('.tim-button');
        const $input = $inputApplier.find('input');

        $input.val(newValue).change();
        $inputApplier.data('value', newValue);
        if (newValue) {
          $button.addClass('selected');
          $button.find('.tim-text').html($inputApplier.data('btn-remove-text'));
        } else {
          $button.removeClass('selected');
          $button.find('.tim-text').html($inputApplier.data('btn-apply-text'));
        }
      });

      $inputApplierComponent.on('invalid_code', function (e) {
        const $inputApplier = $(this);
        $inputApplier.find('tim-input-applier-error').html()
        $inputApplier.trigger('set_value', '');
      });

      if ($inputApplierComponent.data('collapsable')) {
        const $label = $inputApplierComponent.find('.tim-label');
        $label.on('click touch', (e) => {
          const $inputSection = $inputApplierComponent.find('.tim-input-applier-container');
          if ($inputApplierComponent.data('collapsed')) {
            $inputApplierComponent.data('collapsed', false);
            $inputSection.slideDown(500);
            $label.find('svg').addClass('rotate');
          } else {
            $inputApplierComponent.data('collapsed', true);
            $inputSection.slideUp(500);
            $label.find('svg').removeClass('rotate');
          }
        });
      }

      $inputApplierComponent.find('.tim-button').on('click touch', function (e) {
        e.preventDefault();
        const $button = $(this);
        const $inputApplier = $(this).closest('.tim-input-applier');
        const $input = $inputApplier.find('input');

        const isInputApplied = $button.hasClass('selected');

        let $newValue = '';
        if (isInputApplied) {
          $inputApplier.data('value', '');
          $input.val('').change();
          $button.removeClass('selected');
          $button.find('.tim-text').html($inputApplier.data('btn-apply-text'));
        } else {
          $newValue = $input.val()
          $inputApplier.data('value', $newValue);
          $button.addClass('selected');
          $button.find('.tim-text').html($inputApplier.data('btn-remove-text'));
        }

        // refreshView($inputApplier);
        $inputApplier.trigger('value_changed', $newValue);
      });

    }
  });

})(window, document, jQuery);
